<template>
  <a-page-header @back="() => $router.go(-1)" title="DETALLE INDICADOR" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
        <a-breadcrumb-item><router-link to="/indicador">Indicador</router-link></a-breadcrumb-item>
        <a-breadcrumb-item
          ><router-link :to="'/indicador/' + idindicador">Resumen</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item>Detalle</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex gap-2 mb-4 md:gap-3 lg:gap-4">
    <div class="w-full border bg-white font-semibold">
      <p class="text-black">
        <span class="bg-green-600 py-2 px-6 text-white"
          >Indicador: {{ indicadorData.abreviatura }}
        </span>
      </p>
      <p class="px-4 py-2 lg:text-lg lg:p-4">
        {{ indicadorData.denominacion }}
      </p>
    </div>
  </div>

  <div class="flex flex-wrap mb-4 w-full md:flex-nowrap">
    <fieldset class="border border-solid border-gray-300 w-full lg:w-4/5">
      <legend class="text-sm text-left">Filtrar el indicador</legend>
      <IndicadorDetalleFiltros
        @fetchDetalleIndicador="fetchDetalleIndicador"
        @changePeriodo="fetchDetalleIndicador"
        :sigla="sigla"
      />
    </fieldset>

    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:w-auto">
      <legend class="text-sm text-center">Acciones</legend>

      <button @click="handleDownload" class="text-center">
        <div class="text-3xl text-green-700">
          <FileExcelOutlined />
        </div>
        <p>Descargar</p>
      </button>
    </fieldset>
  </div>

  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :columns="columnsDetalle"
      :data-source="indicadorFormated"
      size="small"
      :pagination="false"
      rowKey="documento"
      :loading="isLoading"
      :rowClassName="
        (record, index) =>
          index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
      "
    >
      <template #rangepages="{ index }">
        <p class="text-center">{{ (curretPage - 1) * 10 + index + 1 }}</p>
      </template>

      <template #numerador="{ text: numerador }">
        <span>
          <a-tag :color="numerador === '1' ? 'green' : 'volcano'">
            {{ numerador === "1" ? "Cumple" : "No cumple" }}
          </a-tag>
        </span>
      </template>
    </a-table>
  </div>

  <footer class="flex flex-col md:flex-row items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex">
      <a-pagination v-model:current="curretPage" v-model:pageSize="pageSize" :total="totalDocs" />

      <button class="ml-4" @click="reloadFetch">
        <ReloadOutlined />
      </button>
    </div>
    <p>Total de registros: {{ totalDocs }}</p>
  </footer>
</template>

<script>
import { ref, watch, computed, toRaw, onMounted, reactive, onUnmounted } from "vue";
import { columnsDetalle } from "./utilsIndicador";
import IndicadorDetalleFiltros from "./IndicadorDetalleFiltros.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ReloadOutlined, FileExcelOutlined } from "@ant-design/icons-vue";
import ApiDetalleIndicador from "@/api/detalle_indicador";
import ApiIndicador from "@/api/indicador";
import ApiDescargas from "@/api/descargas";

export default {
  components: {
    IndicadorDetalleFiltros,
    // icons
    ReloadOutlined,
    FileExcelOutlined
  },
  setup() {
    const pageSize = ref(10);
    const route = useRoute();
    const sigla = ref("");
    const store = useStore();
    const idindicador = ref("");
    const curretPage = ref(1);
    const totalDocs = ref(null);
    const isVisible = ref(false);
    const detalleIndicador = ref([]);
    const isLoading = ref(false);
    const indicadorData = reactive({
      denominacion: "",
      abreviatura: ""
    });

    const fetInfo = async () => {
      const response = await ApiIndicador.getInfo(toRaw(idindicador.value));
      sigla.value = response.data[0].sigla;
    };

    const fetchInfo = (idindicador = 1) => {
      ApiIndicador.getInfo(idindicador)
        .then((res) => {
          store.dispatch("detalleindicador/setSigla", res.data[0].sigla);
          indicadorData.denominacion = res.data[0].denominacion;
          indicadorData.abreviatura = res.data[0].abreviatura;
        })
        .catch((err) => console.log(err));
    };

    const fetchDetalleIndicador = (currentPage = 1) => {
      const search = store.getters["detalleindicador/search"];
      const filter = store.getters["detalleindicador/filter"];

      const distrito = store.getters["detalleindicador/distrito"];
      const provincia = store.getters["detalleindicador/provincia"];
      const establecimiento = store.getters["detalleindicador/establecimiento"];
      const periodo_inicio = store.getters["detalleindicador/periodo_inicio"];
      const periodo_fin = store.getters["detalleindicador/periodo_fin"];

      isLoading.value = true;
      ApiDetalleIndicador.getAll(
        {
          currentPage,
          search,
          filter,
          distrito,
          provincia,
          establecimiento,
          periodo_inicio,
          periodo_fin
        },
        toRaw(idindicador.value),
        toRaw(sigla.value)
      )
        .then((response) => {
          detalleIndicador.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(async () => {
      idindicador.value = route.params?.indicador;

      await fetInfo();
      fetchInfo(toRaw(idindicador.value));
      fetchDetalleIndicador();
    });

    onUnmounted(() => {
      store.dispatch["detalleindicador/cleanFilters"];
    });

    watch(curretPage, () => {
      fetchDetalleIndicador(curretPage.value);
    });

    const reloadFetch = () => {
      fetchDetalleIndicador(curretPage.value);
    };

    const indicadorFormated = computed(() =>
      detalleIndicador.value.map((d) => {
        return { ...d, nacimiento: d.fechanacimiento };
      })
    );

    const handleDownload = () => {
      const search = store.getters["detalleindicador/search"];
      const filter = store.getters["detalleindicador/filter"];

      const distrito = store.getters["detalleindicador/distrito"];
      const provincia = store.getters["detalleindicador/provincia"];
      const establecimiento = store.getters["detalleindicador/establecimiento"];
      const periodo_inicio = store.getters["detalleindicador/periodo_inicio"];
      const periodo_fin = store.getters["detalleindicador/periodo_fin"];

      ApiIndicador.ReporteDetalleIndicadorv2(
        { search, filter, distrito, provincia, establecimiento, periodo_inicio, periodo_fin },
        toRaw(idindicador.value),
        toRaw(sigla.value),
        toRaw(sigla.value),
        indicadorData.denominacion
      ).then((response) => {
        window.open(process.env.VUE_APP_BASE_API + '/CSV/' + response.data["nombre_Archivo"], '_blank');
      }).catch((err) => console.log(err));
    };

    return {
      isLoading,
      columnsDetalle,
      // data,
      pageSize,
      curretPage,
      totalDocs,
      isVisible,
      reloadFetch,
      indicadorFormated,
      handleDownload,
      indicadorData,
      idindicador,
      fetchDetalleIndicador,
      sigla
    };
  }
};
</script>

<style></style>
