import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getAll: (payload, idindicador, sigla) => {
    var { filter, search, distrito, provincia, establecimiento, periodo_inicio, periodo_fin  } = payload;

    console.log(periodo_fin);

    if(periodo_fin == '') {
      var dd = new Date();

      var anio = dd.getFullYear();
      var mes = (dd.getMonth() < 10) ? '0' + dd.getMonth() : dd.getMonth();
      
      periodo_fin = anio + '' + mes;
    }

    if(periodo_inicio == '') {
      periodo_inicio = '201201';
    }

    var $tabla = `sivi_data.${sigla}`;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_detalle_indicador",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@page",
          value: payload.currentPage,
          type: "int"
        },
        {
          name: "@PERIODO_INI",
          value: `${periodo_inicio}`,
          type: "string"
        },
        {
          name: "@PERIODO_FIN",
          value: `${periodo_fin}`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value:
            "documento|concat(apellidopaterno, ' ', apellidomateno , ' , ' , nombre)|concat(apellidopaterno, ' ', apellidomateno , ' ' , nombre)",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    if(establecimiento == '') {
      request._petitions.push(
        { name: "@filtros_AND", value: "numerador|distrito|provincia|i.idestablecimiento", type: "string" }
      );

      request._petitions.push(
        { name: "@buscar_AND", value: `%${filter}%|%${distrito}%|%${provincia}%|%${establecimiento}%`, type: "string" }
      );
    }
    else if(establecimiento == -1) {
      request._petitions.push(
        { name: "@filtros_AND", value: "numerador|distrito|provincia|i.red", type: "string" }
      );

      request._petitions.push(
        { name: "@buscar_AND", value: `%${filter}%|%${distrito}%|%${provincia}%|%TACNA%`, type: "string" }
      );
    } else if(establecimiento == 0) {
      request._petitions.push(
        { name: "@filtros_AND", value: "numerador|distrito|provincia|i.red", type: "string" }
      );

      request._petitions.push(
        { name: "@buscar_AND", value: `%${filter}%|%${distrito}%|%${provincia}%|%NO PERTENECE A NINGUNA RED%`, type: "string" }
      );
    } else if(establecimiento == -2) {
      request._petitions.push(
        { name: "@filtros_AND", value: "numerador|distrito|provincia", type: "string" }
      );

      request._petitions.push(
        { name: "@buscar_AND", value: `%${filter}%|%${distrito}%|%${provincia}%`, type: "string" }
      );
    } else {
      request._petitions.push(
        { name: "@filtros_AND", value: "numerador|distrito|provincia|i.idestablecimiento", type: "string" }
      );

      request._petitions.push(
        { name: "@buscar_AND", value: `%${filter}%|%${distrito}%|%${provincia}%|%${establecimiento}%`, type: "string" }
      );
    }

    return post(API_SERVICE_QUERY, request);
  }
};
