<template>
  <a-form
    layout="horizontal"
    :model="formState"
    :rules="rules"
    ref="formRefFilter"
    class="flex flex-col items-center gap-4 md:flex-row"
    v-bind="layout"
  >
    <div class="flex flex-col w-full px-8 md:px-4">
      <a-form-item label="Provincia" class="mb-2 w-full" name="search">
        <a-select v-model:value="formState.provincia" @change="handleProvincia" class="w-full">
          <a-select-option value="">TODAS</a-select-option>
          <a-select-option
            :value="provincia.provincia"
            v-for="provincia in provincias"
            :key="provincia.provincia"
          >
            {{ provincia.provincia }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Distrito" class="mb-2 w-full" name="search">
        <a-select v-model:value="formState.distrito" @change="handleDistrito" class="w-full">
          <a-select-option value="">TODOS</a-select-option>
          <a-select-option
            :value="distrito.distrito"
            v-for="distrito in distritos"
            :key="distrito.distrito"
          >
            {{ distrito.distrito }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Establecimiento" class="mb-1 w-full" name="search">
        <a-select
          v-model:value="formState.establecimientos"
          @change="handleEstablecimiento"
          class="w-full"
        >
          <a-select-option value="">TODOS</a-select-option>
          <a-select-option
            :value="establecimiento.idestablecimiento"
            v-for="establecimiento in establecimientos"
            :key="establecimiento.denominacion"
          >
            {{ establecimiento.denominacion }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>

    <div class="flex flex-col w-full px-8">
      <a-form-item label="Estado" name="filter" class="mb-2 w-full">
        <a-select v-model:value="formState.filter">
          <a-select-option value="">Todos</a-select-option>
          <a-select-option value="1">Cumple</a-select-option>
          <a-select-option value="0">No cumple</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Buscar" class="mb-1 w-full" name="search">
        <a-input v-model:value="formState.search" />
      </a-form-item>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <a-form-item label="INICIO" name="periodo" class="mb-2 w-full">
          <a-select
            v-model:value="formState.idperiodo_inicio"
            :value="idperiodoinicio"
          >
            <a-select-option value="">TODOS</a-select-option>
            <a-select-option
              v-for="periodo in periodos"
              :value="periodo.idperiodo"
              :key="periodo.idperiodo"
            >
              {{ periodo.denominacion }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="FIN" name="periodo" class="mb-2 w-full">
          <a-select
            v-model:value="formState.idperiodo_fin"
            :value="idperiodoinicio"
          >
            <a-select-option value="">TODOS</a-select-option>
            <a-select-option
              v-for="periodo in periodos"
              :value="periodo.idperiodo"
              :key="periodo.idperiodo"
            >
              {{ periodo.denominacion }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>

      <!-- <a-form-item label="PERIODO" name="periodo" class="mb-2 w-full">
        <a-select
          v-model:value="formState.idperiodo"
          :value="idperiodoinicio"
          @select="onSelectPeriodo"
        >
          <a-select-option value="">TODOS</a-select-option>
          <a-select-option
            v-for="periodo in periodos"
            :value="periodo.idperiodoevaluacion"
            :key="periodo.idperiodoevaluacion"
          >
            {{
              meses[
                periodo.idperiodoinicio.substr(4, 6) < 10
                  ? periodo.idperiodoinicio.substr(4, 6)[1] - 1
                  : periodo.idperiodoinicio.substr(4, 6) - 1
              ]
            }}
            {{ periodo.idperiodoinicio.substr(0, 4) }} -
            {{
              meses[
                periodo.idperiodofinal.substr(4, 6) < 10
                  ? periodo.idperiodofinal.substr(4, 6)[1] - 1
                  : periodo.idperiodofinal.substr(4, 6) - 1
              ]
            }}
            {{ periodo.idperiodofinal.substr(0, 4) }}
          </a-select-option>
        </a-select>
      </a-form-item> -->

    </div>

    <div class="flex">
      <button @click="applyFilters" type="submit" class="text-center px-3">
        <div class="text-3xl text-blue-400">
          <SearchOutlined />
        </div>
        <p>Buscar</p>
      </button>

      <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
        <div
          class="text-3xl"
          :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
        >
          <ClearOutlined />
        </div>
        <p>Limpiar filtros</p>
      </button>
    </div>
  </a-form>
</template>
<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";
import EstablecimientoApi from "@/api/establecimiento";
import { useRoute } from "vue-router";

import ApiIndicador from "@/api/indicador";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  emits: ["fetchDetalleIndicador"],
  setup(props, { emit }) {
    const store = useStore();
    const provincias = ref([]);
    const distritos = ref([]);
    const establecimientos = ref([]);
    const formRefFilter = ref();
    const isDisabled = ref(true);
    const periodos = ref([]);
    const route = useRoute();
    const idindicador = ref("");

    idindicador.value = route.params?.indicador;

    const formState = reactive({
      departamento: "TACNA",
      provincia: store.getters["detalleindicador/provincia"],
      establecimiento: store.getters["detalleindicador/establecimiento"],
      distrito: store.getters["detalleindicador/distrito"],
      filter: store.getters["detalleindicador/filter"],
      search: store.getters["detalleindicador/search"],
      //idperiodo_inicio : store.getters["detalleindicador/periodo"],
      //idperiodo_fin : store.getters["detalleindicador/periodo"],
      idperiodo_inicio: "",
      idperiodo_fin : ""
    });

    const meses = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SET",
      "OCT",
      "NOV",
      "DIC"
    ];

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const fetchPeriodos = async () => {
      const id = toRaw(idindicador.value);

      ApiIndicador.getIndicadoresByIDindicadorv2(id)
        .then((response) => {
          console.log(response.data);
          periodos.value = response.data;
          console.log(periodos.value);
        })
        .catch((err) => console.log(err));
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          const { filter, search, idperiodo_inicio, idperiodo_fin } = toRaw(formState);
          store.dispatch("detalleindicador/setFilter", filter);
          store.dispatch("detalleindicador/setSearch", search);
          store.dispatch("detalleindicador/setPeriodoInicio", idperiodo_inicio);
          store.dispatch("detalleindicador/setPeriodoFin", idperiodo_fin);
          emit("fetchDetalleIndicador");
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {

      cleanFilters();
      fetchPeriodos();
      getProvincias(formState.departamento);
    });

    const getProvincias = (filtro) => {
      EstablecimientoApi.getAllProvincias(filtro)
        .then((response) => {
          provincias.value = response.data;
          formState.distrito = "";
          getDistritos(formState.provincia);
        })
        .catch((err) => console.log(err));
    };

    const getDistritos = (filtro) => {
      EstablecimientoApi.getAllDistritos({ filtro })
        .then((response) => {
          distritos.value = response.data;

          getEstablecimientos();
        })
        .catch((err) => console.log(err));
    };

    const onSelectPeriodo = (periodo) => {
      store.dispatch("detalleindicador/setPeriodo", periodo);
      emit("changePeriodo", 1);
    };

    const getEstablecimientos = () => {
      EstablecimientoApi.getAllEstablecimientos(formState.provincia, formState.distrito)
        .then((response) => {
          establecimientos.value = response.data;
          console.log(establecimientos);
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.filter = "";
      formState.search = "";
      formState.provincia = "";
      formState.distrito = "";
      formState.establecimientos = "";
      store.dispatch("detalleindicador/cleanFilters");
      emit("fetchDetalleIndicador");
    };

    const handleProvincia = (value) => {
      store.dispatch("detalleindicador/setProvincia", value);
      store.dispatch("detalleindicador/setDistrito", "");
      store.dispatch("detalleindicador/setEstablecimiento", "");
      formState.distrito = "";
      formState.establecimientos = "";

      getDistritos(value);
      getEstablecimientos();
    };

    const handleDistrito = (value) => {
      store.dispatch("detalleindicador/setDistrito", value);
      store.dispatch("detalleindicador/setEstablecimiento", "");
      formState.establecimientos = "";
      getEstablecimientos();
    };

    const handleEstablecimiento = (value) => {
      store.dispatch("detalleindicador/setEstablecimiento", value);
    };

    const layout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 18,
        offset: 1
      }
    };

    return {
      formState,
      applyFilters,
      establecimientos,
      formRefFilter,
      cleanFilters,
      periodos,
      handleDistrito,
      rules,
      handleProvincia,
      handleEstablecimiento,
      distritos,
      provincias,
      layout,
      isDisabled,
      onSelectPeriodo,
      meses
    };
  }
};
</script>
